<template>
  <div class="bg-white border shadow rounded-lg p-4 pt-1 mb-4 md:mb-10">
    <view-file-modal
      v-show="image"
      :blob="image"
      blobType="image/jpeg"
      @closeModal="image = null"
    ></view-file-modal>
    <div class="grid grid-cols-6 gap-4">
      <div class="col-start-1 col-end-3">
        <p class="text-black opacity-75 text-xs text-left ">Início</p>
        <p class="text-black opacity-75 text-base font-semibold text-left">
          {{
            opportunity.dataInicio
              ? $moment(opportunity.dataInicio).format("DD/MM/YYYY")
              : ""
          }}
        </p>
      </div>
      <div class="col-end-8 col-span-1">
        <p class="text-black opacity-75 text-xs text-left ">Objetivo</p>
        <p class="text-black opacity-75 text-base font-semibold text-left">
          {{ opportunity.comoAjudaraEmpresa }}
        </p>
      </div>
    </div>

    <table>
      <tr>
        <transition name="slide">
          <div>
            <div class="flex my-2 mx-6 items-center justify-center">
              <carousel
               ref="carousel"
               @navigation-click="navigationHandler"
                class="carousel"
                v-if="opportunity.fotos && opportunity.fotos.length"
                :perPage="1"
                :navigateTo="0"
                :navigationClickTargetSize="8"
                navigationNextLabel="<i class='fas fa-chevron-right text-3xl text-secondary'></i>"
                navigationPrevLabel="<i class='fas fa-chevron-left text-3xl text-secondary'></i>"
                :navigationEnabled="true"
                :paginationPadding="2"
                paginationActiveColor="#5316CD"
                paginationColor="#51515150"
                :loop="true"
              >
                <slide v-if="opportunity.idVideoYoutube">
                    <iframe
                      class="embed-responsive-item youtube-video embed-responsive embed-responsive-16by9"
                      :src="`https://www.youtube.com/embed/${opportunity.idVideoYoutube}?enablejsapi=1&version=3&playerapiid=ytplayer&rel=0`"
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      style="max-width: 100%;max-height: 100%; object-fit: scale-down; width: 100%; height: 300px;"
                      allowfullscreen
                    ></iframe>
                </slide>
                <slide v-for="item in opportunity.fotos" :key="item">
                  <transition name="slide">
                    <img
                      class="w-full h-88 object-contain cursor-pointer"
                      :src="item"
                      alt="fotos"
                      @click="image = item"
                    />
                  </transition>
                </slide>
              </carousel>
              <p v-else-if="opportunity.fotos">Não há fotos.</p>
            </div>
          </div>
        </transition>
      </tr>
      <tr>
        <transition name="slide">
          <p class="text-sm text-black opacity-75 py-4 pr-4 text-justify">
            {{ opportunity.defesa }}
          </p>
        </transition>
      </tr>
      <tr>
        <td @click="showInforCompany = !showInforCompany">
          <span
            ><i :class="`fas fa-${showInforCompany ? 'minus' : 'plus'}`"></i
          ></span>
          Informações da Empresa
        </td>
        <transition name="slide">
          <div v-show="showInforCompany">
            <div class="flex my-2 items-center justify-between">
              <information-company :opportunity="opportunity" />
            </div>
          </div>
        </transition>
      </tr>
      <tr>
        <td @click="showGuarantors = !showGuarantors">
          <span
            ><i :class="`fas fa-${showGuarantors ? 'minus' : 'plus'}`"></i
          ></span>
          Avalistas
        </td>
        <transition name="slide">
          <div v-show="showGuarantors">
            <div class="my-2">
              <p class="text-sm text-black opacity-75 py-2 pr-4 font-semibold">
                {{
                  opportunity.avalistas
                    ? opportunity.avalistas.split(",").join(", ")
                    : ""
                }}
              </p>
              <p class="text-sm text-black opacity-75 py-2 pr-4">
                *Avalista é aquele que aceita ser responsável pelo pagamento da
                dívida em nome da empresa. Isso significa que o(a) sócio(a)
                acima assume a dívida da empresa em caso de inadimplência e pode
                ser acionado judicialmente.
              </p>
            </div>
          </div>
        </transition>
      </tr>
      <tr>
        <td @click="showDebt = !showDebt">
          <span><i :class="`fas fa-${showDebt ? 'minus' : 'plus'}`"></i></span>
          Endividamento
        </td>
        <transition name="slide">
          <div class="w-full" v-if="showDebt">
            <pie-chart
              v-if="
                opportunity.endividamento &&
                  opportunity.endividamento.curtoPrazo != 0 &&
                  opportunity.endividamento.medioPrazo != 0 &&
                  opportunity.endividamento.longoPrazo != 0
              "
              class="chart-container"
              :data="getDebtChartData()"
              :options="pieOptions"
            />
            <p v-else>Não há dados de endividamento.</p>
          </div>
        </transition>
      </tr>
      <tr>
        <td @click="showBilling = !showBilling">
          <span
            ><i :class="`fas fa-${showBilling ? 'minus' : 'plus'}`"></i
          ></span>
          Faturamento Anual
        </td>
        <transition name="slide">
          <div v-if="showBilling">
            <bar-chart
              v-if="
                opportunity.faturamento &&
                  opportunity.faturamento.temFaturamento
              "
              class="chart-container"
              :data="getBillingChartData()"
              :options="barOptions"
            />
            <p v-else>Não há dados de faturamento anual.</p>
          </div>
        </transition>
      </tr>
      <tr>
        <td @click="showLocation = !showLocation">
          <span
            ><i :class="`fas fa-${showLocation ? 'minus' : 'plus'}`"></i
          ></span>
          Localização
        </td>
        <transition name="slide">
          <div v-show="showLocation">
            <div class="flex my-2 items-center justify-between">
              <iframe
                v-if="opportunity.endereco"
                width="100%"
                height="450"
                frameborder="0"
                style="border:0"
                :src="
                  `https://www.google.com/maps/embed/v1/place?q=${opportunity.endereco}&key=${googleMapsApiKey}`
                "
                allowfullscreen
              >
              </iframe>
              <p v-else>Não há fotos.</p>
            </div>
          </div>
        </transition>
      </tr>
    </table>
  </div>
</template>

<script>
import InformationCompany from "@/components/Investor/Opportunity/Forms/InformationCompany";
import BarChart from "@/components/Charts/BarChart";
import PieChart from "@/components/Charts/PieChart";
import ViewFileModal from "@/components/Modal/ViewFileModal";
import MoneyFilter from "@/mixins/moneyFilter";

export default {
  name: "profile-forms",
  props: ["opportunity"],
  mixins: [MoneyFilter],
  components: { InformationCompany, BarChart, PieChart, ViewFileModal },
  data() {
    return {
      showInforCompany: true,
      showPhotos: false,
      showGuarantors: false,
      showDebt: false,
      showBilling: false,
      showLocation: false,
      showNotifications: false,
      image: null
    };
  },
  methods: {
    navigationHandler(direction) {
      if(this.opportunity.idVideoYoutube){
          if(this.$refs.carousel.currentPage){
        	  document.querySelector('.youtube-video').contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
          }
      }
    },
    getBillingChartData() {
      const data = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: "rgba(113, 193, 82, 0.7)",
            label: "Faturamento"
          }
        ]
      };
      Object.entries(this.opportunity.faturamento).forEach(([key, value]) => {
        if (key.includes("mes")) {
          data.labels.push(`${value.mes}/${value.ano}`);
          data.datasets[0].data.push(value.valor);
        }
      });
      return data;
    },
    getDebtChartData() {
      const data = {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [
              "rgba(229, 62, 62, 0.7)",
              "rgba(236, 201, 75, 0.7)",
              "rgba(113, 193, 82, 0.7)"
            ]
          }
        ]
      };
      const debt = this.opportunity.endividamento;
      data.datasets[0].data.push(debt.curtoPrazoPorcentagem);
      data.labels.push("Curto prazo");
      data.datasets[0].data.push(debt.medioPrazoPorcentagem);
      data.labels.push("Médio prazo");
      data.datasets[0].data.push(debt.longoPrazoPorcentagem);
      data.labels.push("Longo prazo");
      return data;
    }
  },
  computed: {
    barOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [
            {
              ticks: {
                callback: value => this.formatMoney(value)
              }
            }
          ]
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return this.formatMoney(tooltipItem.yLabel);
            }
          }
        }
      };
    },
    pieOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              return `${data.labels[tooltipItem.index] || ""}: ${
                data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              }%`;
            }
          }
        }
      };
    },
    googleMapsApiKey() {
      return process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
    }
  }
};
</script>

<style lang="sass" scoped>
// Tabela
table
  @apply w-full border-none

table tr
  @apply py-1 w-full
  &:focus
    @apply outline-none

table td
  @apply border-b border-primary w-full flex items-center py-1 opacity-75 text-sm text-primary font-semibold cursor-pointer select-none
  @screen lg
    @apply text-base

table td span
  @apply text-sm py-1 mr-3
  @screen lg
    @apply mr-2
  &>i
    @apply text-xs

.partner_name
  @apply font-bold opacity-75
  @screen lg
    @apply w-1/2

.hint
  @apply text-xs text-right
  @screen lg
    @apply w-1/2

.chart-container
  @apply relative flex my-2 items-center justify-center
  width: calc(100vw - 5rem)
  @screen sm
    width: 70vw
  @screen md
    width: 40vw
  @screen lg
    width: 100%

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video
  position: absolute
  top: 0
  bottom: 0
  left: 0
  width: 100%
  height: 100%
  border: 0


</style>

<style lang="sass">
.VueCarousel-navigation
  .VueCarousel-navigation-button
    &:focus
      outline: 0 !important
.VueCarousel-pagination .VueCarousel-dot-container
  .VueCarousel-dot
    &:focus
      @apply outline-none

.VueCarousel-slide
  visibility: visible
  flex-basis: 100%
  width: 100%
</style>
