<template>
<div>
  <div class="backdrop" />
    <div class="modal">

      <div class="container  ctlModal">
              <h2 class="title">{{title}}</h2>
              <p class="text">{{text}}</p>

              <div class="loading flex justify-center items-center">
              <i class="fas fa-spinner fa-spin text-secondary"></i>
          </div>
      </div>
    </div>
    </div>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import MoneyFilter from '@/mixins/moneyFilter'

export default {
  name: 'loading-modal',
  components: { VButton },
  mixins: [MoneyFilter],
  props: ['text','title'],

  data () {
    return {

    }
  },
  methods: {

}
}
</script>

<style lang="sass" scoped>

.loading
  cursor: default
  margin-top: 40px
  font-size: 3em
  @screen md
    margin-top: 25px
    font-size: 2em

.ctlModal
  position: absolute
  padding: 0
  z-index: 40
  @screen lg
  margin: 0 auto

.modal
  @apply fixed flex justify-center items-center
  z-index: 99
  left: 50%
  top: 50%
  transform: translate(-50%, -50%)
  background: rgb(255, 255, 255)
  padding: 20px
  width: 100%
  height: 100%
  @screen md
   @apply rounded-lg
   border-color: #e2e8f0
   min-height: 247px
   width: 500px
   height: auto


.title
  color: #595959
  font-size: 22px
  text-align: center
  font-weight: 600
  text-transform: none
  margin: 0 0 .4em
  display: block
  word-wrap: break-word
  @screen md
    font-size: 30px

.text
  font-size: 18px
  text-align: center
  font-weight: 300
  line-height: normal
  padding: 0 20px 0 20px
  color: #545454
  word-wrap: break-word

.backdrop
  @apply fixed w-full h-full top-0 left-0
  z-index: 99
  background: rgba(29,26,26,.50)

.content
  @apply relative bg-white z-50 flex flex-col justify-center


</style>
