<template>
  <div>
    <loading-modal
      v-if="showLoadingModal"
      :title="propsLoadingModal.title"
      :text="propsLoadingModal.text"
    />
    <contribute-modal
      v-if="showContributeModal"
      :opportunity="opportunity"
      @update="initial"
      @closeModal="showContributeModal = false"
      @setInvoice="setInvoice"
      @hideLoadingModal="hideLoadingModal = true"
      @showLoadingModal="showLoadingModalAction"
      @refresh="initial"
    />
    <div class="flex flex-wrap">
      <div class="row my-4">
        <p class="title" v-on:click="scroolTo">{{ opportunity.nomeEmpresa }}</p>
      </div>
      <div class="row relative">
        <transition name="fade">
          <div class="backdrop" v-show="loadingPage">
            <div class="fas fa-dollar-sign spin"></div>
          </div>
        </transition>
        <profile-forms class="w-full md:w-8/12" :opportunity="opportunity" />
        <resume
          class="w-full md:w-4/12 md:px-8"
          :opportunity="opportunity"
          @showModalContribute="showContributeModal = true"
          @setInvoice="setInvoice"
        />
        <transition name="fade">
          <invoice-modal
            v-if="showInvoiceModal"
            :invoice="invoice"
            @closeModal="showInvoiceModal = false"
          />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileForms from "@/components/Investor/Opportunity/Forms/ProfileForms";
import ContributeModal from "@/components/Investor/Modal/ContributeModal";
import Resume from "@/components/Investor/Opportunity/Resume";
import Dashboard from "@/services/Investor/Dashboard";
import InvoiceModal from "@/components/Investor/Modal/InvoiceModal";
import LoadingModal from "@/components/Investor/Modal/LoadingModal";

export default {
  name: "opportunity",
  components: {
    ProfileForms,
    Resume,
    ContributeModal,
    InvoiceModal,
    LoadingModal
  },
  props: ["id"],
  data() {
    return {
      showInvoiceModal: false,
      loadingPage: false,
      opportunity: {},
      showContributeModal: false,
      showLoadingModal: false,
      propsLoadingModal: {
        title: null,
        text: null
      }
    };
  },
  async mounted() {
    await this.initial();
  },
  watch: {
    showContributeModal() {
      if (this.showContributeModal) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },
    showLoadingModal() {
      if (this.showLoadingModal) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    },
    showInvoiceModal() {
      if (this.showInvoiceModal) {
        document.body.classList.add("modal-open");
      } else {
        document.body.classList.remove("modal-open");
      }
    }
  },
  methods: {
    showLoadingModalAction(title, text) {
      this.propsLoadingModal.text = text;
      this.propsLoadingModal.title = title;
      this.showLoadingModal = true;
    },
    scroolTo() {
      document.querySelector(".btnSimular").scrollIntoView();
    },
    setInvoice(invoice) {
      this.invoice = invoice;
      this.showInvoiceModal = true;
    },
    async initial(showLoading = true) {
      try {
        if (showLoading) this.loadingPage = true;
        if (!this.$route.params.id) this.$router.push("/investidor/painel");
        this.opportunity = (await Dashboard.getOpportunity(this.id)).data.data;
        if (showLoading) this.loadingPage = false;
      } catch (error) {
        this.$router.push("/investidor/painel");
        console.error(error);
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.row
  @apply w-full flex flex-col
  @screen md
    @apply flex-row

.title
  @apply font-bold text-xl text-secondary my-2 w-full
  @screen lg
    @apply text-2xl my-auto

.backdrop
  @apply fixed w-full h-full bg-white text-5xl text-primary shadow flex items-center justify-center
  opacity: 0.9
  top: 0
  right: 0
  z-index: 9999999

.spin
  animation: rotatey 2s linear infinite

@keyframes rotatey
  from
    transform: rotateY(0deg)
  to
    transform: rotateY(360deg)
</style>
