<template>
  <div class="w-full mt-6">
    <div class="info-container">
      <p class="w-full lg:w-1/3">CNPJ</p>
      <p class="w-full lg:w-2/3">{{ opportunity.cnpj }}</p>
    </div>
    <div class="info-container">
      <p class="w-full lg:w-1/3">Ramo de Atuação</p>
      <p class="w-full lg:w-2/3">{{ opportunity.ramoAtuacao }}</p>
    </div>
    <div class="info-container">
      <p class="w-full lg:w-1/3">Valor Solicitado</p>
      <p class="w-full lg:w-2/3">{{ opportunity.dadosOperacao ? opportunity.dadosOperacao.valorFinanciado : 0 | money }}
        em
        {{ opportunity.dadosOperacao ? opportunity.dadosOperacao.prazo : 0 }} meses</p>
    </div>
    <div class="info-container">
      <p class="w-full lg:w-1/3">Garantia</p>
      <p class="w-full lg:w-2/3">{{ (!opportunity.garantiaRecebiveis ? "Aval dos sócios" :
        `${opportunity.garantiaRecebiveis}% de recebíveis`) }}</p>
    </div>
    <div class="info-container">
      <p class="w-full lg:w-1/3">Taxa Interna de Retorno<sup>Bruta</sup></p>
      <p class="w-full lg:w-2/3">{{ opportunity.dadosOperacao ? opportunity.dadosOperacao.tirAoAno.toFixed(2) : 0 }}% ao
        ano
        / {{ opportunity.dadosOperacao ? opportunity.dadosOperacao.tirAoMes.toFixed(2) : 0 }}% ao mês</p>
    </div>
    <div class="info-container" v-if="opportunity.site">
      <p class="w-full lg:w-1/3">Site</p>
      <p class="w-full lg:w-2/3"><a :href="opportunity.site" target="_blank">{{ opportunity.site }}</a></p>
    </div>
    <div class="info-container" v-if="opportunity.facebook">
      <p class="w-full lg:w-1/3">Facebook</p>
      <p class="w-full lg:w-2/3"><a :href="opportunity.facebook" target="_blank">{{ opportunity.facebook }}</a></p>
    </div>
    <div class="info-container" v-if="opportunity.instagram">
      <p class="w-full lg:w-1/3">Instagram</p>
      <p class="w-full lg:w-2/3"><a :href="opportunity.instagram" target="_blank">{{ opportunity.instagram }}</a></p>
    </div>
    <div class="info-container">
      <p class="w-full lg:w-1/3">Endereço</p>
      <p class="w-full lg:w-2/3">{{ opportunity.endereco }}</p>
    </div>
    <div class="info-container">
      <p class="w-full lg:w-1/3">Faturamento Anual</p>
      <p class="w-full lg:w-2/3">{{ opportunity.faturamentoAnual | money }}</p>
    </div>
    <div class="info-container">
      <p class="w-full lg:w-1/3">Endividamento Total</p>
      <p class="w-full lg:w-2/3">{{ opportunity.dadosOperacao ? opportunity.endividamento.total : 0 | money }}</p>
    </div>
    <div class="info-container">
      <p class="w-full lg:w-1/3">Restrições/Pendências</p>
      <p class="w-full lg:w-2/3">{{ opportunity.restricoes }}</p>
    </div>
    <div class="info-container">
      <p class="w-full lg:w-1/3">Atrasos</p>
      <p class="w-full lg:w-2/3">{{ opportunity.atrasos }}</p>
    </div>
    <div class="info-container">
      <p class="w-full lg:w-1/3">Idade da empresa</p>
      <p class="w-full lg:w-2/3">{{ opportunity.idadeEmpresa }}</p>
    </div>
    <div class="info-container">
      <p class="w-full lg:w-1/3">Número empregados</p>
      <p class="w-full lg:w-2/3">{{ opportunity.qtdeEmpregados }}</p>
    </div>
  </div>
</template>

<script>
import MoneyFilter from '@/mixins/moneyFilter'
export default {
  name: 'information-company',
  props: ['opportunity'],
  mixins: [MoneyFilter]
}
</script>

<style lang="sass" scoped>
.info-container
  @apply bg-gray-100 text-sm
  &:nth-child(even)
    @apply bg-gray-200
  @screen lg
    @apply w-full flex text-base
p
  @apply py-2 pl-2
  &:first-child
    @apply font-bold
</style>
