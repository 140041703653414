<template>
  <transition name="fade">
  <div class="modal">
  <loading-modal v-if="propsLoadingModal.show" :title="propsLoadingModal.title" :text="propsLoadingModal.text" />

    <div class="backdrop" @click="$emit('closeModal')"/>
    <div class="container md:flex md:justify-center md:items-center ctlModal">
      <div class="content">
        <p class="close" @click="$emit('closeModal')">
          <i class="fas fa-times"></i>
        </p>

        <p class="title">Simulação de aporte para {{opportunity.nomeEmpresa}}</p>
        <v-input :options="valoresPermitidos" :searchable='false' itemValue='value' itemText="label" name="value" v-model="value" :error="errors.first('value')" label="Selecione um valor de aporte*" v-validate="{required: true, is_not: -1}"></v-input>

        <div class="row">
          <button @click="showChartYield = true;showPaymentFlow = false" :class="showChartYield ? 'button_active' : ''" class="button mb-2 md:mb-0 md:mr-10">Gráfico de Rendimentos</button>
          <button @click="showPaymentFlow = true;showChartYield = false" :class="showPaymentFlow ? 'button_active' : ''" class="button md:mr-10">Fluxo de Repagamentos</button>
        </div>
        <div class="w-full flex justify-center items-center my-4">
          <div v-if="showChartYield" class="w-full">
            <line-chart
              class="relative w-full h-80 mt-4"
              :chart-data="data"
              :options="lineOptions"
            />
          </div>
          <div v-if="showPaymentFlow" class="w-full h-96 overflow-x-auto lg:overflow-x-hidden">
            <vuetable
              ref="vuetable"
              class="contribute_table"
              :api-mode="false"
              :fields="header"
              :data="simulacao.fluxoRepagamentos"
              :no-data-template="loading ? '' : 'Selecione um valor'"
            >
              <template v-slot:prestacao="props" class="flex items-center">
                {{props.rowData.prestacao | money}}
              </template>
              <template v-slot:saldoInicial="props" class="flex items-center">
                {{props.rowData.saldoInicial | money}}
              </template>
              <template v-slot:juros="props" class="flex items-center">
                {{props.rowData.juros | money}}
              </template>
              <template v-slot:ir="props" class="flex items-center">
                {{props.rowData.ir | money}}
              </template>
              <template v-slot:amortizacao="props" class="flex items-center">
                {{props.rowData.amortizacao | money}}
              </template>
              <template v-slot:saldoDevedor="props" class="flex items-center">
                {{`${formatMoney(props.rowData.saldoDevedor).replace('-', '')}`}}
              </template>
            </vuetable>
          </div>
        </div>
        <div class="info_main">
          <p class="info_main_title">Condições esperadas</p>
          <div class="info">
            <div class="info_left">
              <div class="info_group">
                <p>Valor aporte:</p>
                <p v-if="value > 0">{{simulacao.condicoesEsperadas.valorAporte | money}}</p>
                <p v-else>Selecione acima</p>
              </div>
              <div class="info_group">
                <p>T.I.R. bruta ao mês:</p>
                <p>{{(simulacao.condicoesEsperadas.tirBrutaAoMes).toFixed(2)}}%</p>
              </div>
              <div class="info_group">
                <p>Juros:</p>
                <p>{{simulacao.condicoesEsperadas.juros | money}}</p>
              </div>
              <div class="info_group">
                <p>Imposto de Renda:</p>
                <p>{{simulacao.condicoesEsperadas.impostoRenda | money}}</p>
              </div>
            </div>
            <div class="info_right">
              <div class="info_group">
                <p>Prazo:</p>
                <p>{{simulacao.condicoesEsperadas.prazo}} meses</p>
              </div>
              <div class="info_group">
                <p>T.I.R. bruta ao ano:</p>
                <p>{{(simulacao.condicoesEsperadas.tirBrutaAoAno).toFixed(2)}}%</p>
              </div>
              <div class="info_group">
                <p>Retorno bruto:</p>
                <p>{{simulacao.condicoesEsperadas.retornoBruto | money}}</p>
              </div>
              <div class="info_group">
                <p>Retorno liquido:</p>
                <p>{{simulacao.condicoesEsperadas.retornoLiquido | money}}</p>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!showInfo" class="informations_alert" @click="showInfo = true">
          <p class="informations_alert_title">Veja algumas informações importantes sobre o aporte.</p>
        </div>
        <div v-if="showInfo" class="informations">
          <p>Informações importantes</p>
          <ul>
            <li>
            1. A taxa de retorno esperada apresentada já está líquida de qualquer tarifa cobrada pela Tutu Digital e depende de perdas derivadas de eventual inadimplência do devedor.
            </li>
            <li>
            2. A integralização do aporte será realizada por meio de um boleto bancário registrado em nome do investidor. O pagamento será intermediado por Iugu Serviços na Internet SA.
            </li>
            <li>
            3. O primeiro repagamento será feito aproximadamente 1 mês após assinatura dos contratos.
            </li>
            <li>
            4. Os repagamentos serão creditados em sua Carteira no mesmo dia em que o boleto da parcela do tomador for compensado. O prazo de compensação é de 1 a 4 dias úteis. Você pode usar o saldo acumulado na Carteira para reinvestimentos ou sacar para sua conta bancária.
            </li>
            <li>
            5. A partir de 01/03/2019 o investidor receberá em sua Carteira o valor líquido de cada parcela já descontado o imposto de renda.
            </li>
            <li>
            6. O fluxo de repagamentos é calculado pela Tabela Price que é caracterizada por pagamentos mensais iguais embutindo uma amortização crescente e juros decrescentes.
            </li>
            <li>
            7. Mantenha seus dados pessoais e bancários rigorosamente atualizados, só é possível realizar um aporte se sua conta bancária estiver cadastrada e aprovada.
            </li>
            <li>
            8. Valores sujeitos a pequena variação dependendo da data final de conclusão da operação.
            </li>
          </ul>
        </div>


        <div class="w-full flex justify-center" v-if="value > 0 && !opportunity.encerrado && !opportunity.investidorEstaNaFila && !opportunity.investidorEstaNaFilaEspera">
          <v-radio class="inline " name="type" v-model="type"  label="Usar saldo disponível na Wallet" value="" :disabled="!opportunity.ofertaAbertaParaRegistrosInteresses || opportunity.investidorEstaNaFilaEspera || balance < value"></v-radio>
          <v-radio class="inline" name="type" v-model="type" label="Boleto ou Pix - 100% gratuíto" value="hibrido" :disabled="!opportunity.ofertaAbertaParaRegistrosInteresses || opportunity.investidorEstaNaFilaEspera || balance >= value"></v-radio>
        </div>

        <v-button v-if="!opportunity.encerrado && !opportunity.investidorEstaNaFila && !opportunity.investidorEstaNaFilaEspera" title="Você já está na fila de espera para investir nesta oportunidade." class="w-full flex justify-center"  @click="submit" :loading="loading" :disabled="!opportunity.ofertaAbertaParaRegistrosInteresses || opportunity.investidorEstaNaFilaEspera">Registrar Interesse</v-button>

        <p class="text-center text-sm text-yellow-700 font-bold mb-2" v-if="opportunity.investidorEstaNaFilaEspera">Você já está na fila de espera para investir nesta empresa.</p>
        <p class="text-center text-sm text-green-700 font-bold mb-2" v-if="opportunity.investidorEstaNaFila">Você já registrou interesse nessa empresa </p>

        <div class="safari_only">&emsp;</div>

      </div>
    </div>


  </div>
  </transition>
</template>

<script>
import VInput from '@/components/Inputs/Input'
import VRadio from '@/components/Inputs/Radio'

import VButton from '@/components/Buttons/Button'
import LineChart from '@/components/Charts/LineChart'

import MathTutu from '@/assets/js/math.tutu.core.js'
import MoneyFilter from '@/mixins/moneyFilter'
import Dashboard from '@/services/Investor/Dashboard'
import Wallet from '@/services/Investor/Wallet/Wallet'
import LoadingModal from '@/components/Investor/Modal/LoadingModal'


export default {
  name: 'modal-contribute',
  components: { VInput, VButton, LineChart, VRadio,LoadingModal },
  props: ['opportunity'],
  mixins: [MoneyFilter],
  data () {
    return {
      value: -1,
      type: '',
      balance: 0,
      showChartYield: true,
      showPaymentFlow: false,
      showInfo: false,
      loading: false,
      invoice: null,
       propsLoadingModal: {
        title: null,
        text: null,
        show: false,
      },
      simulacao: {
        graficoRendimentos: [{
          valorAcumulado: 0,
          repagamento: 0
        }],

        fluxoRepagamentos: [{
          parcela: 0,
          prestacao: 0,
          saldoInicial: 0,
          juros: 0,
          ir: 0,
          amortizacao: 0,
          saldoDevedor: 0
        }],

        condicoesEsperadas: {
          valorAporte: 0,
          prazo: 0,
          tirBrutaAoMes: 0,
          tirBrutaAoAno: 0,
          juros: 0,
          retornoBruto: 0,
          impostoRenda: 0,
          retornoLiquido: 0
        }
      },
      header: [
        {
          name: 'parcela',
          title: 'Parcela'
        },
        {
          name: '__slot:prestacao',
          title: 'Prestação'
        },
        {
          name: '__slot:saldoInicial',
          title: 'Saldo Inicial'
        },
        {
          name: '__slot:juros',
          title: 'Juros'
        },
        {
          name: '__slot:ir',
          title: 'IR'
        },
        {
          name: '__slot:amortizacao',
          title: 'Armotização'
        },
        {
          name: '__slot:saldoDevedor',
          title: 'Saldo Devedor'
        }
      ]
    }
  },
  computed: {
    valoresPermitidos () {
      return this.opportunity.valoresPermitidos.map(v => ({
        label: this.formatMoney(v),
        value: v
      }))
    },
    data () {
      return {
        labels: this.simulacao.graficoRendimentos.map(v => `${v.repagamento}º`),
        datasets: [{
          label: 'rep',
          data: this.simulacao.graficoRendimentos.map(v => v.valorAcumulado),
          backgroundColor: 'rgba(113, 193, 82, 0.7)',
          borderColor: 'rgba(113, 193, 82, 0.7)',
          fill: false
        }]
      }
    },
    lineOptions () {
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Repagamento'
            }
          }],
          yAxes: [{
            ticks: {
              callback: value => this.formatMoney(value)
            },
            scaleLabel: {
              display: true,
              labelString: 'Principal + Juros'
            }
          }]
        },
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            title: (tooltipItem, data) => {
              return `${tooltipItem[0].label} repagamento`
            },
            label: (tooltipItem, data) => {
              return this.formatMoney(tooltipItem.yLabel)
            }
          }
        }
      }
    }
  },
  mounted: async function() {
    //this.$emit('showLoadingModal','Estamos gerando sua fatura','Assim que estiver tudo pronto você poderá efetivar seu aporte via PIX ou Boleto. Você também receberá uma cópia por e-mail e SMS.')

  },
  methods: {
   showLoadingModal(title, text){
      this.propsLoadingModal.text = text;
      this.propsLoadingModal.title = title;
      this.propsLoadingModal.show = true;
    },
    async submit () {
      const self = this;
      if (!this.loading) {
        if (await this.$validator.validateAll()) {
          this.loading = true
          try {
            const data = {
              oportunidadeId: this.opportunity.id,
              valor: this.value,
              tipoPagamento: this.type
            }

            const message = (await Dashboard.registerInterest(data)).data.mensagem

                  if (this.type === "hibrido" && message.indexOf('lista de espera') < 0) {
                                            self.esperarFaturaPronta();
                                            self.showLoadingModal('Estamos gerando sua fatura',
                                            'Assim que estiver tudo pronto você poderá efetivar seu aporte via PIX ou Boleto. Você também receberá uma cópia por e-mail e SMS.'
                                            )
                                        }
                  this.$store.dispatch('notification', { type: 'success', message: message })

            self.$emit('closeModal')
            self.$emit('update', false)


          } catch (error) {
            this.$store.dispatch('notification', { type: 'error', message: error.response.data.erros.erro })
          } finally {
            this.loading =false;

          }
        } else {
          this.$store.dispatch('notification', { type: 'error', message: 'Há algum campo inválido. Revise o formulário.' })
        }
      }
    },
    async esperarFaturaPronta(){
                    const self = this;

                    var interval = setInterval(async () => {
                        var data = (await Dashboard.getInvoice(this.opportunity.id)).data

                            if (data.status && data.data) {
                                clearInterval(interval);
                                self.propsLoadingModal.show = false;

                                self.$emit('setInvoice', data.data)
                                self.$emit('closeModal')
                                self.$emit('update')
                            }
                        }, 5000);
    },
    gerarFluxo () {
      this.price = MathTutu.price(this.value, this.opportunity.dadosOperacao.prazo, this.opportunity.dadosOperacao.tirAoMes, 0)
      this.simulacao.fluxoRepagamentos = this.price.parcelas.map(x => ({
        parcela: x.numero,
        prestacao: x.prestacaoRound,
        saldoInicial: x.saldoInicialRound,
        juros: x.jurosRound,
        ir: x.ir,
        amortizacao: x.amortizacaoRound,
        saldoDevedor: x.saldoDevedorRound
      }))

      this.simulacao.condicoesEsperadas = {
        valorAporte: this.value,
        prazo: this.opportunity.dadosOperacao.prazo,
        tirBrutaAoMes: this.opportunity.dadosOperacao.tirAoMes,
        tirBrutaAoAno: this.opportunity.dadosOperacao.tirAoAno,
        juros: MathTutu.roundUp(this.price.parcelas.reduce((atual, prox) => atual + prox.juros, 0), 2),
        retornoBruto: MathTutu.roundUp(this.price.parcelas.reduce((atual, prox) => atual + prox.prestacao, 0), 2),
        impostoRenda: MathTutu.roundUp(this.price.parcelas.reduce((atual, prox) => atual + prox.ir, 0), 2)
      }
      this.simulacao.condicoesEsperadas.retornoLiquido = MathTutu.roundUp(this.simulacao.condicoesEsperadas.retornoBruto - this.simulacao.condicoesEsperadas.impostoRenda, 2)

      let jurosAcumulado = 0
      this.simulacao.graficoRendimentos = []
      for (let i = 0; i < this.price.parcelas.length; i++) {
        jurosAcumulado += this.price.parcelas[i].juros

        this.simulacao.graficoRendimentos.push({
          valorAcumulado: MathTutu.roundUp(this.value + jurosAcumulado, 2),
          repagamento: this.price.parcelas[i].numero
        })
      }
    }
  },
  watch: {
    'propsLoadingModal.show'(){
      console.log('shooow')
      if(this.showLoadingModal){
        document.body.classList.add("modal-open");
      } else{
        document.body.classList.remove("modal-open");
      }
    },
    async value () {
      this.loading = true;
      this.gerarFluxo()
      if (this.$refs.vuetable) this.$refs.vuetable.refresh()
      this.balance = (await Wallet.getBalance()).data.data
      this.type = this.balance >= this.value ? '' : 'hibrido'
      this.loading = false;
    }
  }
}
</script>

<style lang="sass" scoped>
.modal
  @apply fixed flex w-screen h-full left-0 z-50
  @screen md
    @apply items-center top-0 justify-center

.backdrop
  @apply fixed z-40 w-full h-full top-0 left-0
  background: rgba(29,26,26,.50)

.ctlModal
  position: absolute
  margin-top: -5rem
  padding: 0
  @screen lg
    margin-top: 0


.content
  @apply relative w-full h-120 overflow-y-auto bg-white z-50  pt-4 px-4
  height: 100vh
  max-height: 100vh
  @screen lg
    @apply w-10/12 rounded-lg
    max-height: 52rem
    height: 95vh


.title
  @apply text-secondary font-bold mb-2 text-center mt-2
  @screen lg
    @apply text-left text-lg

.close
  @apply absolute right-0 top-0 text-secondary rounded-full p-1 m-1 cursor-pointer
  @screen lg
    @apply m-2 p-2

.row
  @apply w-full flex flex-col
  @screen md
    @apply flex-row justify-center

.info_main
  @apply border border-secondary rounded w-full opacity-75 p-2
  background-color: rgba(72, 27, 144, 0.2)
  &_title
    @apply text-base text-secondary font-bold

.info
  @apply flex flex-col my-2 text-sm text-secondary w-full
  @screen md
    @apply flex-row
  &_left
    @apply w-full
    @screen md
      @apply w-1/2 pr-6
  &_right
    @apply w-full mt-2
    @screen md
      @apply w-1/2 pl-6 mt-0
  &_group
    @apply flex justify-between

.button
  @apply rounded-full bg-white text-gray-600 text-sm shadow-md h-6 px-2
  &:focus
    @apply outline-none
  &_active
    @apply bg-secondary text-white
  &_calendar
    @apply rounded-full bg-white text-gray-600 text-sm shadow-md w-8 h-8 mr-3
    &:focus
      @apply outline-none
    &_active
      @apply bg-secondary text-white

.informations
  @apply w-full py-1 my-4 text-sm
  p:first-child
    @apply font-bold
  @screen lg
    @apply text-gray-600
  &_alert
    @apply w-full border-2 rounded-lg py-1 px-4 my-4 text-center cursor-pointer
    border-color: #CCBEA0
    background: #FFFAF3
    @screen lg
      @apply flex-row items-end
    &_title
      @apply text-sm font-bold text-left
      color: #7A4D05
      @screen lg
        @apply text-base

::-webkit-scrollbar
  @screen md
    width: 8px
  @screen lg
    width: 8px

::-webkit-scrollbar-track
  @screen md
    @apply bg-gray-400 rounded-lg
  @screen lg
    @apply bg-gray-400 rounded-lg

::-webkit-scrollbar-thumb
  @screen md
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
  @screen lg
    @apply bg-gray-600 rounded-lg
    &:active
      @apply bg-secondary
</style>

<style lang="sass">

.contribute_table
  @apply w-full bg-white shadow rounded-lg
  thead tr
    @apply rounded-t-lg
  tbody
    @apply rounded-b-lg
  tr
    &:nth-child(even)
      @apply bg-gray-100
    &:last-child
      @apply rounded-b-lg
      td:first-child
        @apply rounded-bl-lg
      td:last-child
        @apply rounded-br-lg
    th
      @apply p-2 text-sm text-gray-600
      background: #ECECEC
    td
      @apply relative text-gray-600 text-center text-sm border border-gray-300 py-0 px-2

</style>
