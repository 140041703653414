<template>
  <div class="content">
    <img class="logo" :src="opportunity.logotipo">
    <div class="flex flex-col items-center">
      <p class="title">Valor Financiado</p>
      <p class="money">{{opportunity.dadosOperacao ? opportunity.dadosOperacao.valorFinanciado : 0 | money}}</p>
    </div>

    <hr class="line_sm my-6"/>

    <div class="flex w-full">
      <div class="w-1/2 flex flex-col items-center">
        <p class="legend">Duração Esperada</p>
        <p class="title">{{opportunity.dadosOperacao ? opportunity.dadosOperacao.prazo : 0}} meses</p>
      </div>
      <div class="w-1/2 flex flex-col items-center">
        <p class="legend">Rentabilidade</p>
        <p class="title">{{opportunity.dadosOperacao ? opportunity.dadosOperacao.tirAoMes.toFixed(2) : 0}}% a.m</p>
        <p class="legend">{{opportunity.dadosOperacao ? opportunity.dadosOperacao.tirAoAno.toFixed(2) : 0}}% a.a</p>
      </div>
    </div>

    <hr class="line_sm my-6"/>

    <div class="flex flex-col items-center justify-center w-full px-4 md:px-0">
      <p class="legend">Risco de Crédito</p>
      <div class="line">
        <div class="relative flex flex-col items-end ml-2" :class="`w-${rating(opportunity.ratingStr)}/12`">
          <img class="carret" src="@/assets/images/investor_panel/carret.png" alt="carret">
          <p class="risk">{{opportunity.ratingStr}}</p>
        </div>
      </div>
    </div>

    <hr class="line_sm mt-8 mb-4"/>

    <div class="w-full flex flex-col items-center justify-center px-4 mb-4 md:px-0">
      <p class="legend w-full text-center">Já conseguimos...</p>
      <div class="relative flex flex-col w-full h-6 bg-gray-300 rounded-sm">
        <div class="absolute h-full bg-primary flex items-center justify-end" :style="`width:${opportunity.porcentagemLevantada}%`">
          <p class="text-white text-xss mr-1">{{opportunity.porcentagemLevantada}}%</p>
        </div>
      </div>
      <div class="flex justify-between w-full items-center">
        <p class="legend">Total de Apoiadores</p>
        <p class="title">{{opportunity.totalApoiadores}}</p>
      </div>
    </div>
    <v-button class="btnSimular flex justify-center mt-2 md:mt-0" @click="$emit('showModalContribute')" :disabled="!opportunity.ofertaAbertaParaAnalise">Simular aporte</v-button>

   <p class="text-center text-sm text-green-700 font-bold mb-2" v-if="opportunity.investidorEstaNaFila">Você já registrou interesse nessa empresa. <br />
   <span v-if="!isLoading">
    <a href="javascript://" v-on:click="getInvoice"> Clique aqui para ver a fatura de aporte </a>
    </span>
      <span v-else class="fa fa-spinner fa-spin text-lg text-secondary"></span>
  </p>
  </div>
</template>

<script>
import VButton from '@/components/Buttons/Button'
import MoneyFilter from '@/mixins/moneyFilter'
import Dashboard from '@/services/Investor/Dashboard'

export default {
  name: 'opportunity-resume',
  components: { VButton },
  mixins: [MoneyFilter],
  props: ['opportunity'],
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    async getInvoice(){
      this.isLoading = true;
     this.$emit('setInvoice', (await Dashboard.getInvoice(this.opportunity.id)).data.data);
     this.isLoading = false;
    },
    rating (value) {
      switch (value) {
        case 'D2':
          return 1
        case 'C3':
          return 2
        case 'C2':
          return 3
        case 'C1':
          return 4
        case 'B6':
          return 5
        case 'B5':
          return 6
        case 'B4':
          return 7
        case 'B3':
          return 8
        case 'B2':
          return 9
        case 'B1':
          return 10
        case 'A3':
          return 11
        case 'A2':
          return 12
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.legend
  @apply text-xs text-gray-900 opacity-50 my-1

.title
  @apply text-black opacity-75 text-base font-semibold text-left

.money
  @apply text-secondary text-2xl font-semibold

.line_sm
  @apply w-full h-px bg-black opacity-25

.line
  @apply h-3 w-full
  background: linear-gradient(to right,#ec4c49 0,#f9a021 50%,#73c04f 100%)

.risk
  @apply absolute text-black uppercase text-xss font-semibold z-10 select-none
  top: 100%

.logo
  @apply mb-3 object-contain rounded-sm
  max-height: 7rem

.content
  @apply rounded-lg bg-white border shadow flex flex-col items-center py-4 mb-10
  @screen md
    @apply ml-4 sticky
    height: 45rem
    top: 1rem
</style>
